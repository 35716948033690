import {AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors} from '@angular/forms';

import {Injectable} from "@angular/core";


@Injectable()
export class CustomValidators {

    // Using CustomValidators :
    //  1. Static functions can be directly used as they are exposed statically
    //  2. For using other functions which are dependent to other services/promise the class needs to be declared within the providers section in the module

    httpValidateEmail: any;
    httpLoanGiverOrgChecker: any;

    constructor() {}

    static trimmedString(c: AbstractControl): any {
        const message = { 'trimmedString': { 'message': 'Please enter this field.' } };
        return c.value && c.value.trim() ? null : message;
    }



    // -- below functions are preserved for ref.....

    static birthYear(c: FormControl): any {
        const numValue = Number(c.value);
        const currentYear = new Date().getFullYear();
        const minYear = currentYear - 85;
        const maxYear = currentYear - 18;
        const isValid = !isNaN(numValue) && numValue >= minYear && numValue <= maxYear;
        const message = {
            'years': {
                'message': 'The year must be a valid number between ' + minYear + ' and ' + maxYear + '.'
            }
        };
        return isValid ? null : message;
    }

    static countryCity(form: FormGroup): any {
        const countryControl = form.get('location.country');
        const cityControl = form.get('location.city');

        if (countryControl != null && cityControl != null) {
            const country = countryControl.value;
            const city = cityControl.value;
            let error: any;

            if (country === 'France' && city !== 'Paris') {
                error = 'If the country is France, the city must be Paris';
            }

            const message = {
                'countryCity': {
                    'message': error
                }
            };

            return error ? message : null;
        }
    }
    static uniqueName(c: FormControl): Promise<any> {
        const message = {
            'uniqueName': {
                'message': 'The name is not unique'
            }
        };

        return new Promise(resolve => {
            setTimeout(() => {
                resolve(c.value === 'skanta.it@gmail.com' ? message : null);
            }, 1000);
        });
    }

    static telephoneNumber(c: FormControl): any {
        const isValidPhoneNumber = /^\d{3,3}-\d{3,3}-\d{3,3}$/.test(c.value);
        const message = {
            'telephoneNumber': {
                'message': 'The phone number must be valid (XXX-XXX-XXX, where X is a digit)'
            }
        };
        return isValidPhoneNumber ? null : message;
    }

    static telephoneNumbers(form: FormGroup): any {

        const message = {
            'telephoneNumbers': {
                'message': 'At least one telephone number must be entered'
            }
        };

        const phoneNumbers = <FormArray>form.get('phoneNumbers');
        const hasPhoneNumbers = phoneNumbers && Object.keys(phoneNumbers.controls).length > 0;

        return hasPhoneNumbers ? null : message;
    }

    // --------------
    static customRequired(c: AbstractControl): any {
        const message = { 'customRequired': { 'message': 'Dette feltet er obligatorisk.' } };
        return c.value ? null : message;
    }

    static customEmail(c: AbstractControl): any {
        const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        const message = { 'customRequired': { 'message': 'Please enter a valid email.' } };
        return (c.value && regexp.test(c.value)) ? null : message;
    }

    static numberRequired(c: AbstractControl): any {
        const message = { 'customRequired': { 'message': 'Please enter this field.' } };
        return c.value === null ? message: null ;
    }

    static customStartDate(c: AbstractControl): any {
        const message = { 'customRequired': { 'message': 'Please select date.' } };
        return c.value ? null : message;
    }

    static customSelect(c: AbstractControl): any {
        const message = { 'customRequired': { 'message': 'Please select an option.' } };
        return c.value ? null : message;
    }

    static customMultiSelect(c: AbstractControl): any {
        const message = { 'customRequired': { 'message': 'Please select option(s).' } };
        let val: any = c.value;
        return Array.isArray(val) && val.length ? null : message;
    }

    static customDate(c: AbstractControl): any {
        const message = { 'customRequired': { 'message': 'Please select a date.' } };
        return c.value ? null : message;
    }

    static businessName(c: AbstractControl): any {
        const message = { 'businessName': { 'message': `Please enter a valid business name.` }};
        return c.value ? null : message;
    }

    static contactName(c: AbstractControl): any {
        const message = { 'contactName': { 'message': `Please enter a valid contact name.` } };
        return c.value ? null : message;
    }

    static userType(c: AbstractControl): any {
        const message = { 'userType':{ 'message': `Please select user type.`} };
        return c.value ? null : message;
    }

    static selectTeam(c: AbstractControl): any {
        const message = { 'selectTeam':{ 'message': `Please select a team.`} };
        return c.value ? null : message;
    }

    static selectPerson(c: FormControl): any {
        const message = { 'selectTeam':{ 'message': `Please select atleast one person.`} };
        return c.value ? null : message;
    }

    static customerType(c: FormControl): any {
        const message = { 'customerType': { 'message': `Please select customer type.`} };
        return c.value ? null : message;
    }

    static jobTitle(c: FormControl): any {
        const message = { 'jobTitle': { 'message': `Please select job title.`} };
        return c.value ? null : message;
    }

    static streetAddress(c: FormControl): any {
        const message = { 'streetAddress': { 'message': `Please enter street address.`} };
        return c.value ? null : message;
    }

    static address(c: FormControl): any {
        const message = { 'streetAddress': { 'message': `Please enter address.`} };
        return c.value ? null : message;
    }

    static city(c: FormControl): any {
        const message = { 'city': { 'message': `Please enter city.`} };
        return c.value ? null : message;
    }


    static statusSelect(c: FormControl): any {
        const message = { 'statusSelect': { 'message': `Please select status.`} };
        return c.value ? null : message;
    }

    static state(c: FormControl): any {
        const message = { 'state': { 'message': `Please select state.`} };
        return c.value ? null : message;
    }

    static stateCustom(c: FormControl): any {
        const message = { 'state': { 'message': `Please enter state.`} };
        return c.value ? null : message;
    }

    static zip(c: FormControl): any {
        const message = { 'state': { 'message': `Please enter zip.`} };
        return c.value ? null : message;
    }

    static country(c: FormControl): any {
        const message = { 'country': { 'message': `Please select country.`} };
        return c.value ? null : message;
    }


    static customRequiredWithValidEmail(c: any): any {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        const EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
        const error = {
            'customRequired': {
                'message': 'Vennligst skriv inn en e-post.'
            }
        };
        if(!c.value) {
            return error;
        } else if (c.value.length <= 5 || !re.test(c.value)) {
            error.customRequired.message = 'Vennligst skriv inn en gyldig e-post.';
           return error;
        }else {
            return null;
        }
    }

    static customValidEmailValidator(c: FormControl): any {
       var re=/^(([^<>()\[\]\\,;:\s@"]+(\.[^<>()\[\]\\,;:\s@"]+)*)|)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const error = {
            'customRequired': {
                'message': 'Please enter email.'
            }
        };
        if(!c.value) {
            return error;
        } else if (c.value.length <= 5 || !re.test(c.value)) {
            error.customRequired.message = 'Please enter a valid email';
            return error;
        }else {
            return null;
        }
    }

    static acceptToContinue(c: any): any {
        const message = {
            'userName': {
                'message': `Vennligst godta for å fortsette.`
            }
        };
        return c.value ? null : message;
    }

    static firstName(c: FormControl): any {
        const message = {
            'firstName': {
                'message': `Please enter firstname.`
            }
        };
        return c.value ? null : message;
    }

    static lastName(c: FormControl): any {
        const message = {
            'firstName': {
                'message': `Please enter lastname.`
            }
        };
        return c.value ? null : message;
    }


    static teamLeader(c: FormControl): any {
        const message = {
            'teamLeader': {
                'message': 'Please select team leader.'
            }
        };
        return c.value && c.value.length ? null : message;
    }
    static assignee(c: FormControl): any {
        const message = {
            'assignee': {
                'message': 'Please select assignee.'
            }
        };
        return c.value ? null : message;
    }

    static contactTitle(c: FormControl): any {
        const message = {
            'contact_title': {
                'message': "Please enter 'Contact'."
            }
        };
        return c.value ? null : message;
    }

    static contactRelationship(c: FormControl): any {
        const message = {
            'contact_title': {
                'message': "Please enter 'Relationship'."
            }
        };
        return c.value ? null : message;
    }



    static positionEmpType(c: FormControl): any {
        const message = {
            'employee_type_id': {
                'message': "Please enter 'Employee Type'."
            }
        };
        return c.value ? null : message;
    }

    static employeePosition(c: FormControl): any {
        const message = {
            'employee_type_id': {
                'message': "Please enter 'Employee Position'."
            }
        };
        return c.value ? null : message;
    }

    static positionJobTitle(c: FormControl): any {
        const message = {
            'employee_type_id': {
                'message': "Please enter 'Job Title'."
            }
        };
        return c.value ? null : message;
    }
    // --------




    static noteSubject(c: FormControl): any {
        const message = {
            'assignee': {
                'message': 'Please enter subject.'
            }
        };
        return c.value ? null : message;
    }
    static folder(c: FormControl): any {
        const message = {
            'assignee': {
                'message': 'Please select folder.'
            }
        };
        return c.value ? null : message;
    }
    static visibility(c: FormControl): any {
        const message = {
            'assignee': {
                'message': 'Please select visibility.'
            }
        };
        return c.value ? null : message;
    }
    static details(c: FormControl): any {
        const message = {
            'assignee': {
                'message': 'Please enter details.'
            }
        };
        return c.value ? null : message;
    }

    static assetItem(c: FormControl): any {
        const message = {
            'item': {
                'message': 'Please enter Item Name.'
            }
        };
        return c.value ? null : message;
    }
    static assetDescription(c: FormControl): any {
        const message = {
            'description': {
                'message': 'Please enter Item Description.'
            }
        };
        return c.value ? null : message;
    }
    static assetLoaned(c: FormControl): any {
        const message = {
            'date_loaned': {
                'message': 'Please enter Date Loaned.'
            }
        };
        return c.value ? null : message;
    }
    static assetSerial(c: FormControl): any {
        const message = {
            'serial': {
                'message': 'Please enter Serial No.'
            }
        };
        return c.value ? null : message;
    }

    static licenceNo(c: FormControl): any {
        const message = {
            'licence_no': {
                'message': 'Please enter Drivers Licence #.'
            }
        };
        return c.value ? null : message;
    }
    static dlState(c: FormControl): any {
        const message = {
            'dl_state': {
                'message': 'Please select DL State.'
            }
        };
        return c.value ? null : message;
    }
    static dlExp(c: FormControl): any {
        const message = {
            'dl_expiration': {
                'message': 'Please select DL Expiration.'
            }
        };
        return c.value ? null : message;
    }
    static dlClass(c: FormControl): any {
        const message = {
            'driver_class': {
                'message': 'Please select Driver Classification.'
            }
        };
        return c.value ? null : message;
    }

    static hasAnySelectedRecipient(farr: any): any {
        let isValid = false;
         if(farr.length){
             for (let itemFg of farr.controls) {
                 let fg = itemFg as FormGroup;
                 if(fg.controls['IsSelected'].value){
                     isValid = true;
                 }
             }
         }
        const message = {
            'driver_class': {
                'message': 'Please select atleast one item.'
            }
        };
        return isValid ? null : message;
    }

    static hasSelectedEmailOrSms(fg: any): any {
        let isValid = false;
        const isSmsOpted = fg.controls['IsSMSOpted'].value;
        const ssEmailOpted = fg.controls['IsEmailOpted'].value;
        if(isSmsOpted || ssEmailOpted){
            isValid = true;
        }
        const message = {
            'driver_class': {
                'message': 'Please select any of the SMS or Email.'
            }
        };
        return isValid ? null : message;
    }
    static customDocSigningValidator(form: AbstractControl):any {
        let docId = form.parent?.value.docId;
        let FileName = form.value
        const message = { 'customRequired': { 'message': 'validation.CUSTOM_REQUIRED' } };
        let result = docId != "" || FileName != ""
        return result ? null : message
    }

  static validPassword(c: any): any {
    /*
    The password must be at least 8 characters long
    and contain at least three of the following:
    - a capital letter
    - a small letter
    - a number
    - a symbol
     */

    const regexHasCapital = /[A-Z]+/g;
    const regexHasSmall = /[a-z]+/g;
    const regexHasNumber = /[0-9]+/g;
    const regexHasSymbol = /~|!|@|#|\$|%|\^|&|\*|\(|\)|-|_|\+|=|\[|\]|\{|\}|\||\\|:|\;|<|>|\?|\//g;
    const regexHasCountMinEight = /^.{8,}$/g;

    const ctrlVal: any = c.value;
    let validCount = 0;
    if(regexHasCapital.test(ctrlVal)){
      validCount++;
    }
    if(regexHasSmall.test(ctrlVal)){
      validCount++;
    }
    if(regexHasNumber.test(ctrlVal)){
      validCount++;
    }
    if(regexHasSymbol.test(ctrlVal)){
      validCount++;
    }
    let isValid = true;
    if(validCount < 3){
      isValid = false
    }
    if(ctrlVal.length < 8){
      isValid = false
    }
    const error = {
      'validPassword': {
        'message': 'Ugyldig passord.' // Invalid password
      }
    };
    if(!isValid) {
      return error;
    }else {
      return null;
    }
  }

  hasCapital(ctrlVal: any){
    const regexHasCapital = /[A-Z]+/g;
    let result = false;
    if(regexHasCapital.test(ctrlVal)){
      result = true
    }
    return result;
  }

  hasSmall(ctrlVal: any){
    const regexHasSmall = /[a-z]+/g;
    let result = false;
    if(regexHasSmall.test(ctrlVal)){
      result = true
    }
    return result;
  }

  hasNumber(ctrlVal: any){
    const regexHasNumber = /[0-9]+/g;
    let result = false;
    if(regexHasNumber.test(ctrlVal)){
      result = true
    }
    return result;
  }

  hasMinCountEight(ctrlVal: any){
    const regexHasCountMinEight = /^.{8,}$/g;
    let result = false;
    if(regexHasCountMinEight.test(ctrlVal)){
      result = true
    }
    return result;
  }

  hasSymbol(ctrlVal: any){
    const regexHasSymbol = /~|!|@|#|\$|%|\^|&|\*|\(|\)|-|_|\+|=|\[|\]|\{|\}|\||\\|:|\;|<|>|\?|\//g;
    let result = false;
    if(regexHasSymbol.test(ctrlVal)){
      result = true
    }
    return result;
  }
}
